import React from 'react';
import HeroSlider, { Slide, MenuNav} from 'hero-slider';
import image1 from "../images/img1.png";
import image2 from "../images/img2.png";
import image3 from "../images/img3.png";
import image4 from "../images/img4.png";

const BasicSlider = () => {
    return (
        <HeroSlider
            height="55vh"
            autoplay={{
                autoplayDuration: 4000
            }}
            controller={{
                slidingDuration: 250,
                slidingDelay: 0,
                onSliding: (nextSlide) =>
                  console.debug('onSliding(nextSlide): ', nextSlide),
                onBeforeSliding: (previousSlide, nextSlide) =>
                  console.debug(
                    'onBeforeSliding(previousSlide, nextSlide): ',
                    previousSlide,
                    nextSlide
                  ),
                onAfterSliding: (nextSlide) =>
                  console.debug('onAfterSliding(nextSlide): ', nextSlide)
              }}
            style={{borderRadius: "8px"}}
        >
            <Slide 
                background={{
                    backgroundImageSrc: image1,
                }}
            >
                <div style={{display: "flex", justifyContent: "space-around", height: "100%"}}>
                    <div style={{marginTop: "2%", fontSize: "1.6em", fontWeight: "bold", color: "#447988"}}>
                        Find new roles the moment they're live
                    </div>
                </div>
            </Slide>
            <Slide 
                background={{
                    backgroundImageSrc: image2,
                }}
            >
                <div style={{display: "flex", justifyContent: "space-around", height: "100%"}}>
                    <div style={{marginTop: "3%", fontSize: "1.6em", fontWeight: "bold", color: "#447988"}}>
                        Organize your applications effectively
                    </div>
                </div>
            </Slide>
            <Slide 
                background={{
                    backgroundImageSrc: image3,
                }}
            >
                <div style={{display: "flex", justifyContent: "space-around", height: "100%"}}>
                    <div style={{marginTop: "1.5%", fontSize: "1.6em", fontWeight: "bold", color: "#447988"}}>
                        Keep Notes on each job
                    </div>
                </div>
            </Slide>
            <Slide 
                background={{
                    backgroundImageSrc: image4,
                }}
            >
                <div style={{display: "flex", justifyContent: "space-around", height: "100%"}}>
                    <div style={{marginTop: "3%", fontSize: "1.6em", fontWeight: "bold", color: "#447988"}}>
                        Stay up to date with an interactive dashboard
                    </div>
                </div>
            </Slide>
            <MenuNav />
        </HeroSlider>
    )
}

export default BasicSlider;