import React, { Component } from 'react';
import axios from "axios";
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import DeleteModal from './myjobsdeletemodal';
import AddModal from './myjobsaddmodal';
import DetailModal from './myjobsdetailmodal';
import { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const columns = {
  [uuid()]: {
    name: "Looking",
    items: [],
    tileColorHold: "#4A4F49",
    textColor: "white",
    tileColor: "#A7A7A7",
    backgroundColor: "#e8e8e8"
  },
  [uuid()]: {
    name: "Applied",
    items: [],
    tileColor: "#355C67",
    tileColorHold: "#2C4B54",
    textColor: "white",
    backgroundColor: "#e8f7fa"
  },
  [uuid()]: {
    name: "Interviewing",
    items: [],
    tileColor: "#C18A38",
    tileColorHold: "#C18A38",
    textColor: "white",
    backgroundColor: "#f7eddc"
  },
  [uuid()]: {
    name: "Offer",
    items: [],
    tileColor: "#55A630",
    tileColorHold: "#4F982D",
    textColor: "white",
    backgroundColor: "#e7f7df"
  },
  [uuid()]: {
    name: "Rejected",
    items: [],
    tileColorHold: "#BABCBD",
    textColor: "#940B0E",
    tileColor: "#940B0E",
    backgroundColor: "#fae3e4"
  }
};

function CustomToggle({ children, eventKey, color, holdColor, dragging }) {
  const currEventKey = useContext(AccordionContext);
  let isExpanded = false;
  if (currEventKey.activeEventKey != null) {
    isExpanded = currEventKey.activeEventKey.length > 0;
  }

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("accordion component click"),
  );

  return (
    <button
      type="button"
      style={{
        backgroundColor: dragging ? holdColor : color,
      }}
      onClick={decoratedOnClick}
    >
      <div className='expand-minimize-icon'
        style={{
          fontSize: 'large',
          textAlign: 'left',
          fontSize: '0.88em'
        }}>
        {isExpanded ? 'hide details -' : 'show details +'}
      </div>
    </button>
  );
}


export default class MyJobs extends Component {
  state = {
    columns: columns,
    modalIsOpen: false,
    autoFillLink: "",
    newJobName: null,
    newJobCompany: null,
    newJobApplicationLink: "",
    newJobPortalLink: "",
    newJobNotes: "",
    newJobStatus: "",
    jobDetailModalIsOpen: false,
    jobAddModalIsOpen: false,
    modalTabIndex: 0,
    autofillLoading: false,
    confirmOpen: false,
    currJob: null,
    events: {},
    neweventModalIsOpen: false,
    newEventCompany: null,
    newEventNotes: null,
    newEventTitle: null,
    newEventDate: null,
    deleteModalIsOpen: false,
    eventsList: [],
    toDelete: null,
  }

  componentDidMount() {
    axios.post(`${this.props.apiURL}/job-data-user`, { useremail: this.props.user }).then((response) => {
      let columnsCopy = JSON.parse(JSON.stringify(columns));
      for (let job of response.data) {
        for (let entry of Object.entries(columnsCopy)) {
          if (entry[1].name == job.status) {
            entry[1].items.push(job)
          }
        }
      }
      this.setState({ columns: columnsCopy });
    });
    axios.post(`${this.props.apiURL}/event-data-user`, { useremail: this.props.user }).then((response) => {
      const events = {}
      const eventsList = []
      for (const event of response.data) {
        events[event.id] = event;
        eventsList.push(event);
      }
      this.setState({ events, eventsList })
    });
  }

  closeNewEventModal = () => {
    this.setState({
      neweventModalIsOpen: false,
      newEventCompany: null,
      newEventNotes: null,
      newEventTitle: null,
      newEventDate: null,
    })
  }

  changeName = (e) => {
    this.setState({ newJobName: e })
  }

  mount = () => {
    this.componentDidMount();
  }

  changeCompany = (e) => {
    this.setState({ newJobCompany: e })
  }

  changeNotes = (e) => {
    this.setState({ newJobNotes: e })
  }

  changeApplicationLink = (e) => {
    this.setState({ newJobApplicationLink: e })
  }

  changeAutoFillLink = (e) => {
    this.setState({ autoFillLink: e })
  }

  changePortalLink = (e) => {
    this.setState({ newJobPortalLink: e })
  }

  changeStatus = (e) => {
    this.setState({ newJobStatus: e })
  }

  update = (job, status) => {
    console.log(job);
    if (status != null) {
      job.status = status;
    }
    axios.post(`${this.props.apiURL}/user-job-update`, job).then((response) => {
      if (status == null) {
        this.closeJobDetailModal();
        this.componentDidMount();
      }
    });
  }

  changeCurrJob = (currJob) => {
    this.setState({ currJob })
  }

  openDeleteModal = () => {
    this.setState({ toDelete: this.state.currJob, deleteModalIsOpen: true });
  }

  addJob = () => {
    const job = {
      jobId: null,
      jobName: this.state.newJobName,
      company: this.state.newJobCompany,
      status: this.state.newJobStatus,
      useremail: this.props.user,
      portalLink: this.state.newJobPortalLink,
      applicationLink: this.state.newJobApplicationLink,
      notes: this.state.newJobNotes,
    }
    console.log(job);
    axios.post(`${this.props.apiURL}/user-job-add`, job).then((response) => {
      if (response.status == 200) {
        this.setState({
          jobModalIsOpen: false,
          columns: columns,
          newJobName: null,
          newJobCompany: null,
          newJobApplicationLink: "",
          newJobPortalLink: "",
          newJobNotes: "",
          newJobStatus: ""
        }, () => {
          this.closeJobAddModal()
          this.componentDidMount()
        });
      }
    });
  }

  changeModalTabIndex = (event, newTab) => {
    //console.log(newTab)
    this.setState({ modalTabIndex: newTab })
  }

  changeContinueTabIndex = () => {
    if (this.state.modalTabIndex == 3) {
      this.setState({ modalTabIndex: 0 })
    } else {
      //let nextTab = this.state.modalTabIndex+=1
      this.setState({ modalTabIndex: this.state.modalTabIndex += 1 })
    }

  }

  openConfirmDialog = () => {
    this.setState({ confirmOpen: true })
  }

  closeConfirmDialog = () => {
    this.setState({ confirmOpen: false })
  }

  autoFillJob = async () => {
    const fillLink = this.state.autoFillLink
    await this.setState({ autofillLoading: true })

    axios.post(`${this.props.apiURL}/autofill-job`, { link: this.state.autoFillLink }).then((response) => {
      console.log("axios in")
      if (response.data != null) {
        const info = response.data
        this.setState({
          autofillLoading: false,
          newJobName: info[0],
          newJobCompany: info[1],
          newJobLocation: info[2],
          newJobStatus: "Looking",
          newJobNotes: info[3],
          newJobApplicationLink: fillLink,
          modalTabIndex: 1,

        })
      }
    })
  }


  autoFillCancel = () => {
    // if (source) {
    //   source.cancel("autofill request canceled");
    // }
    this.setState({ autofillLoading: false });
  }


  deleteJob = (id) => {
    axios.post(`${this.props.apiURL}/user-job-delete`, { _id: id }).then((response) => {
      if (response.status === 200) {
        this.componentDidMount();
      }
    })
  }

  closeJobDetailModal = () => {
    this.setState({ jobDetailModalIsOpen: false, })
  }

  closeJobAddModal = () => {
    this.setState({
      jobAddModalIsOpen: false,
      confirmOpen: false,
      newJobName: "",
      newJobCompany: null,
      newJobApplicationLink: "",
      newJobPortalLink: "",
      newJobNotes: "",
      newJobStatus: "",
      autoFillLink: "",
      modalTabIndex: 0
    })
  }

  closeDeleteModal = () => {
    this.setState({ deleteModalIsOpen: false, toDelete: null })
  }

  render() {
    return (
      <div>
        {!this.state.jobAddModalIsOpen &&
          <Fab variant="extended" color="primary" style={{ position: "fixed", bottom: "20px", right: "20px" }} onClick={() => { this.setState({ jobAddModalIsOpen: true }) }}>
            <AddIcon />
            Add Job
          </Fab>
        }
        <AddModal jobAddModalIsOpen={this.state.jobAddModalIsOpen}
          newJobName={this.state.newJobName}
          newJobStatus={this.state.newJobStatus}
          newJobLocation={this.state.newJobLocation}
          newJobApplicationLink={this.state.newJobApplicationLink}
          newJobCompany={this.state.newJobCompany}
          newJobNotes={this.state.newJobNotes}
          modalTabIndex={this.state.modalTabIndex}
          changeModalTabIndex={this.changeModalTabIndex}
          changeContinueTabIndex={this.changeContinueTabIndex}
          closeJobAddModal={this.closeJobAddModal}
          autofillLoading={this.state.autofillLoading}
          addJob={this.addJob}
          autoFillJob={this.autoFillJob}
          autoFillCancel={this.autoFillCancel}
          changeName={this.changeName}
          changeCompany={this.changeCompany}
          changePortalLink={this.changePortalLink}
          changeNotes={this.changeNotes}
          changeStatus={this.changeStatus}
          changeApplicationLink={this.changeApplicationLink}
          changeAutoFillLink={this.changeAutoFillLink}
          confirmOpen={this.state.confirmOpen}
          openConfirmDialog={this.openConfirmDialog}
          closeConfirmDialog={this.closeConfirmDialog}
        />



        <DeleteModal deleteJob={this.deleteJob} deleteModalIsOpen={this.state.deleteModalIsOpen} closeDeleteModal={this.closeDeleteModal} toDelete={this.state.toDelete} />
        {this.state.currJob != null && <DetailModal
          currJob={this.state.currJob}
          jobDetailModalIsOpen={this.state.jobDetailModalIsOpen}
          closeJobDetailModal={this.closeJobDetailModal}
          events={this.state.events}
          eventsList={this.state.eventsList}
          openDeleteModal={this.openDeleteModal}
          changeCurrJob={this.changeCurrJob}
          update={this.update}
          useremail={this.props.user}
          apiURL={this.props.apiURL}
          mount={this.mount}
        />}


        <div style={{ display: "flex", justifyContent: "center", height: "100%", marginTop: "10px", backgroundColor: "#f7f7f7"}}>
          <DragDropContext
            onDragEnd={result => {
              if (!result.destination) return;
              const { source, destination } = result;
              if (source.droppableId !== destination.droppableId) {
                const sourceColumn = this.state.columns[source.droppableId];
                const destColumn = this.state.columns[destination.droppableId];
                const sourceItems = [...sourceColumn.items];
                const destItems = [...destColumn.items];
                const [removed] = sourceItems.splice(source.index, 1);
                this.update(removed, destColumn.name);
                destItems.splice(destination.index, 0, removed);
                this.setState({
                  columns: {
                    ...this.state.columns,
                    [source.droppableId]: {
                      ...sourceColumn,
                      items: sourceItems
                    },
                    [destination.droppableId]: {
                      ...destColumn,
                      items: destItems
                    }
                  }
                });
              } else {
                const column = this.state.columns[source.droppableId];
                const copiedItems = [...column.items];
                const [removed] = copiedItems.splice(source.index, 1);
                copiedItems.splice(destination.index, 0, removed);
                this.setState({
                  columns: {
                    ...this.state.columns,
                    [source.droppableId]: {
                      ...column,
                      items: copiedItems
                    }
                  }
                });
              }
            }}
          >
            {Object.entries(this.state.columns).map(([columnId, column], index) => {
              //console.log(index);
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  key={columnId}
                >
                  <div>
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            style={{
                              background: snapshot.isDraggingOver
                                ? "#f2fcff"
                                : "transparent",
                              width: "19.9vw",
                              height: "96vh",
                              position: "relative",
                            }}
                          >
                            <div style={{ fontSize: "1.22em", letterSpacing: "1.2px", textAlign: "center", paddingTop: "80px", paddingBottom: "5px", borderBottom: "1px solid #447988", backgroundColor: "#f7f7f7"}}>
                              <span style={{borderRadius: "5px"}}>{column.name}</span>
                              <span><BookmarkIcon fontSize='small' style={{color: column.tileColor, marginBottom: "1px"}}/></span>
                            </div>
                            <div style={{overflow: "auto", height: "81vh", borderLeft: (index == 0) ? "0px solid #447988" : "1px solid #447988", padding: "10px"}}
                              {...provided.droppableProps}
                              ref={provided.innerRef}>
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.company}
                                    draggableId={item.company}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            minHeight: "50px",
                                            color: "white",
                                            ...provided.draggableProps.style
                                          }}
                                        >

                                          <Card
                                            onClick={() => {
                                              this.setState({ jobDetailModalIsOpen: true });
                                              this.setState({ currJob: JSON.parse(JSON.stringify(item)) });
                                            }}
                                            className={`card-${item.status}`}
                                            style={{
                                              borderRadius: 0,
                                              color: 'black',
                                              backgroundColor: snapshot.isDragging ? "#DCDCDC" : "white",
                                              marginBottom: "8px",
                                              textAlign: "left",
                                            }}

                                          >

                                            <Card.Body style={{ marginBottom: "0px", padding: "0.75rem" }}>
                                              <div style={{ letterSpacing: "1px", fontWeight: "bold" }}>
                                                {item.company}
                                              </div>
                                              <div>
                                                {item.jobName.length < 75 ? item.jobName : `${item.jobName.slice(0,75)}...`}
                                              </div>
                                            </Card.Body>
                                          </Card>

                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                            </div>
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
    );

  }


}
