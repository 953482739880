import React, { Component } from 'react';


export default class Profile extends Component {


    render () {

        return (
            <div className="page-background"
            style={{
                height: "100%",
                width: "100%",
            }}
            >

                

            </div>
        );



    }
}