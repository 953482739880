import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import React, {Component, useEffect, useState} from 'react';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";


export default class UserPieChartMinimized extends React.Component {

    state = {
        labels: [],
        nums: [],
        colors: [],
        renderChart: false,
        jobs: false,
    }

    componentDidMount() {
        axios.post(`${this.props.apiURL}/job-data-user`, { useremail: this.props.user }).then((response) => {
            const metrics = {Looking: 0, Applied: 0, Interviewing: 0, Offer: 0, Rejected: 0};
            for (let job of response.data) {
                if (job.status in metrics) {
                    metrics[job.status] += 1
                }
            }
            let colorObj = {Looking: "#A7A7A7", Applied: "#355C67", Interviewing: "#E09F3E", Offer: "#55A630", Rejected: "#940B0E"};
            const labels = [];
            const nums = [];
            const colors = [];
            for (const [label,num] of Object.entries(metrics)) {
                labels.push(`${label} (${String(num)})`);
                nums.push(num);
                colors.push(colorObj[label]);
                if (num != 0) this.setState({jobs: true});
            }
            this.setState({labels, nums, colors}, ()=>{this.setState({renderChart: true})});
          });
    }

    render() {
      return (
        <div id="user-pie-chart">
            {this.state.renderChart ? 
                <div>
                    {this.state.jobs ?
                    <Chart
                    type='donut'
                    series={this.state.nums}
                    width={240}
                    height={240}
                    animations={{
                        enabled: "false"
                    }}

                    options={{
                        
                        labels: this.state.labels,
                        plotOptions:{
                            pie: {
                                donut: {
                                    size: '55%'
                                }
                            }
                        },
                        legend:{
                            position: 'bottom',
                            horizontalAlign: 'left',
                            fontSize: '12px',
                            fontFamily: 'nunito', 
                            fontWeight: 'bold',
                        },
                        colors: this.state.colors,
                        dataLabels: {
                            enabled: false
                        }
                    }}
                    >

                    </Chart> : <div style={{marginLeft: "15px", marginRight: "15px", fontSize: "0.8em"}}>
                        You haven't added any jobs yet. Explore them on the Find Jobs page or add them manually to see user metrics.
                    </div>
                    }
                </div>
            
            : 
            <div style={{marginLeft: "73%"}}>
            <ClipLoader
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            </div>}
        </div>
      );
    }}