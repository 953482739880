import React, {useState} from 'react';
import './../index.css';
import { Link } from "react-router-dom";
import clongimageprimarycolor from "../images/clonglogoprimarycolor.png"

const NavBar = (props) => {
  return (
    <div>
       <div className="navbar" style={{ display: "flex", justifyContent: "space-between", transition: "2s"}}>
          <div style={{display: "flex"}}>
            <div className="logo" style={{ marginRight: "10px" }}>
              <Link style={{ textDecoration: 'none' }} to="/dashboard" onClick={() => {props.editSelected("dashboard")}}>
                <img src={clongimageprimarycolor} style={{width: "150px", paddingBottom: "5px"}}/>
              </Link>
            </div>
            <div className = {props.selected == "dashboard" ? "navbuttonselected" : "navbutton"}>
              <Link style={{ textDecoration: 'none'}} to="/dashboard" onClick={() => {props.editSelected("dashboard")}}>
                Dashboard
              </Link>
            </div>
            <div className = {props.selected == "newjobs" ? "navbuttonselected" : "navbutton"}>
              <Link style={{ textDecoration: 'none'}} to="/newjobs" onClick={() => {props.editSelected("newjobs")}}>
                Find Jobs
              </Link>
            </div>
            <div className = {props.selected == "myjobs" ? "navbuttonselected" : "navbutton"} >
              <Link style={{ textDecoration: 'none'}} to="/myjobs" onClick={() => {props.editSelected("myjobs")}}>
                My Applications
              </Link>
            </div>
            {/* <div className = {props.selected == "calendar" ? "navbuttonselected" : "navbutton"} >
              <Link style={{ textDecoration: 'none'}} to="/calendar" onClick={() => {props.editSelected("calendar")}}>
                Calendar
              </Link>
            </div> */}
          </div>
          <div style={{display: "flex"}}>
            <div style={{ marginRight: "50px", color: "grey"}} >
              <div style={{ marginTop: "13.2px", color: "#447988", fontWeight: props.selected == "profile" ? "bold":"normal"}} >
                <Link style={{ textDecoration: 'none', paddingTop: "8px"}} to="/profile" onClick={() => {props.editSelected("profile")}}>
                  {props.user != null && 
                    <div style={{display: "flex"}}>
                      {props.signedInWithPassport ? 
                      <img src={props.user.photos[0].value} style={{borderRadius: "50%", width: "33px", height: "33px", marginTop: "-5px", marginRight: "10px"}}/> : 
                      <div style={{color: "white", backgroundColor: "#447988", width: "33px", height: "33px", marginTop: "-5px", marginRight: "10px", borderRadius: "50%", fontSize: "1.2em", display: "flex", justifyContent: "center"}}>
                        <div style={{alignSelf: "center"}}>
                          {props.user.displayName[0]}
                        </div>
                      </div>
                      }
                      <div>{props.user.displayName}</div>
                    </div>
                  }
                </Link>
              </div>
            </div>
            <div className="logout">
              <button onClick={() => { props.logout() }}>logout</button>
            </div>
          </div>
        </div>
    </div>
  );
}

export default NavBar;


