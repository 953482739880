import React, { Component, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import notifications from '../mockdata/notifications.json';
import axios from "axios";


export default class NotificationsWidget extends Component {

    state = {
        events: null,
        eventRefs: null,
    }

    componentDidMount() {
        axios.post(`${this.props.apiURL}/event-data-user`, { useremail: this.props.user }).then((response) => {
            const events = {}
            const eventRefs=[];
            let today = new Date();
            today = new Date(today.getFullYear(),today.getMonth(),today.getDate());
            let month = today.getMonth()+1;
            const oneMonthFromNow=new Date(today.getFullYear(),month,today.getDate());
            for (const event of response.data) {
                const date = new Date(event.date);
                console.log(date)
                
                if (date >= today && date < oneMonthFromNow) {
                    events[event.id] =  event;
                    eventRefs.push({title: event.id, start: date});
                }
            }
            eventRefs.sort((a,b) => ( a.start > b.start ? 1 : -1));
            this.setState({events, eventRefs});
        });
      }

    render() {
        return (
            <div className='widget-wrapper'>
                {this.state.eventRefs != null && this.state.events != null && <div className='widget' style={{
                    width: '42vw',
                    height: "40vh"
                }}>
                    <div style={{width: "100%", marginBottom: "10px", borderBottom: "2px solid #447988", fontSize: "1.2em", fontWeight: "bold"}}>
                        Notifications
                    </div>
                    {this.state.eventRefs.length > 0 ? <div style={{overflow: "auto", maxHeight: "30vh"}}>
                    {this.state.eventRefs.map(eventRef => {
                        const event = this.state.events[eventRef.title];
                        return (
                            <div style={{margin: "10px", paddingBottom: "10px", borderBottom: "1px solid grey"}}>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{fontWeight: "bold", letterSpacing: "0.5px"}}>{event.name}&nbsp;<span style={{fontWeight: "normal", color: "grey"}}>{event.company != null && event.company}</span></div>
                                    <div style={{fontWeight: "bold"}}>{eventRef.start.toString().split(" ").slice(0,3).join(" ")}</div>
                                </div>
                                <div>{event.notes}</div>
                            </div>
                        );
                    })}
                    </div> :
                    <div style={{margin: "10px"}}>
                        There are no notifications for the next month
                    </div>}
                </div>}
            </div>
        );
    }
}