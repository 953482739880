import React, { Component, useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import axios from "axios";
import Modal from 'react-modal';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ModalHeader } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';


export default class ScheduleCalendar extends Component {

    state = {
        month: (new Date()).getMonth(),
        year: (new Date()).getYear() + 1900,
        date: (new Date((new Date()).getYear() + 1900,(new Date()).getMonth(),1)),
        events: {},
        eventRefs: [],
        modalIsOpen: false,
        newEventApplication: null,
        newEventNotes: null,
        newEventTitle: null,
        newEventDate: null,
        editModalIsOpen: false,
        editModalId: null,
        userJobs: [],
    }

    componentDidMount() {
        axios.post(`${this.props.apiURL}/event-data-user`, { useremail: this.props.user }).then((response) => {
            const events = {}
            const eventRefs=[];
            for (const event of response.data) {
                events[event.id] =event;
                const date = new Date(event.date);
                eventRefs.push({title: event.id, start: date});
            }
            this.setState({events, eventRefs})
        });
        axios.post(`${this.props.apiURL}/job-data-user`, { useremail: this.props.user }).then((response) => {
                this.setState({userJobs: response.data});
            });
      }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
            newEventCompany: null,
            newEventNotes: null,
            newEventTitle: null,
            newEventDate: null,
        })
    }

    closeEditModal = () => {
        this.setState({
            editModalIsOpen: false,
            editModalEventCompany: null,
            editModalEventNotes: null,
            editModalEventTitle: null,
            editModalEventDate: null,
            editModalId: null,
        })
    }

    addEvent = () => {
        const event = {
          name: this.state.newEventTitle,
          date: this.state.newEventDate,
          useremail: this.props.user,
          notes: this.state.newEventNotes,
          company: this.state.newEventApplication != null ? this.state.newEventApplication.company : null
        }
        axios.post(`${this.props.apiURL}/add-event`, event).then((response) => {
          if (response.status == 200) {
              if (this.state.newEventApplication != null) {
                let app = this.state.newEventApplication;
                app.upcomingEvent = response.data._id;
                axios.post(`${this.props.apiURL}/user-job-update`, app);
              }
            this.closeModal();
            this.componentDidMount();
          }
        });
      }


    editEvent = () => {
        const event = {
            name: this.state.editModalEventTitle,
            date: this.state.editModalEventDate,
            useremail: this.props.user,
            notes: this.state.editModalEventNotes,
            company: this.state.editModalEventCompany,
            _id: this.state.editModalId
          }
          axios.post(`${this.props.apiURL}/event-update`, event).then((response) => {
            if (response.status == 200) {
              this.closeEditModal();
                this.componentDidMount();
            }
          });
    }

    delete = () => {
        axios.post(`${this.props.apiURL}/delete-event`, {_id: this.state.editModalId}).then((response) => {
            if (response.status == 200) {
              this.closeEditModal();
              this.componentDidMount();
            }
          });
    }

    renderEventContent = (eventInfo) => {
        const event = this.state.events[eventInfo.event.title];
        let timeText = eventInfo.timeText.slice(0,-1) + " " + (eventInfo.timeText.slice(-1,eventInfo.timeText.length)).toUpperCase() + "M";
        if (eventInfo != null && event != null) {
            return (
                <button className="calendar-event" onClick={() => {
                    this.setState({
                        editModalIsOpen: true,
                        editModalId: event.id,
                        editModalEventNotes: event.notes,
                        editModalEventTitle: event.name,
                        editModalEventDate: event.date,
                        editModalEventCompany: event.company,
                    })
                    
                    }}>
                    <div className="calendar-event-header">
                        <div style={{marginLeft: "5px", fontWeight: "bold"}}>{event.name}</div>
                        <div style={{fontSize: "0.9em", marginRight: "5px"}}>{timeText}</div>
                    </div>
                    {event.company != null && 
                    <div className="calendar-event-header">
                        <div style={{fontSize: "0.84em", marginLeft: "5px"}}>
                            {event.company}
                        </div>
                        <div />
                    </div>}
                </button>
                )
        }
      }


    render() {
        return (
        <div className="calendar-container" 
            style={{
                width: "80vw",  
                marginTop: "100px", 
                height: "70vh", 
                marginLeft: "10vw"
            }}>
            <Fab variant="extended" color="primary" style={{ position: "fixed", bottom: "20px", right: "20px" }} onClick={() => { this.setState({ modalIsOpen: true }) }}>
                <AddIcon />
                Add Event
            </Fab>
            <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={{
                    content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    }
                }}
                contentLabel="Example Modal"
                >
                
                <div style={{ display: "flex", justifyContent: "space-between", }}>
                    <div>{" "}</div>
                    <div style={{ textAlign: "center", fontSize: "1.7em", marginBottom: "20px", fontWeight: "lighter" }}>Event details</div>
                    <button style={{ marginTop: "-20px" }} onClick={() => { this.closeModal() }}><CloseIcon /></button>
                </div>

                <div><TextField id="outlined-basic" label="Event Title" variant="outlined" style={{ width: "40vw", marginBottom: "14px", backgroundColor: "#f7f7f7", }}
                    onChange={e => { this.setState({ newEventTitle: e.target.value }) }} /></div>
                <div style={{width: "40vw", marginBottom: "14px"}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{display: "flex", width: "40vw"}}>
                                <div style={{marginRight: "10px", justifySelf: 'flex-start'}}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={this.state.newEventDate}
                                        renderInput={(params) => <TextField style={{backgroundColor: "#f7f7f7"}} {...params} />}
                                        onChange={(newValue) => {this.setState({newEventDate: newValue})}}
                                    />
                                </div>
                                <div style={{justifySelf: "flex-end"}}>
                                    <TimePicker
                                        label="Time"
                                        value={this.state.newEventDate}
                                        onChange={(newValue) => {this.setState({newEventDate: newValue})}}
                                        renderInput={(params) => <TextField  style={{backgroundColor: "#f7f7f7"}} {...params} />}
                                    />
                                </div>
                            </div>
                    </LocalizationProvider>
                </div>
                <div><TextField id="outlined-basic" label="Notes (Optional)" variant="outlined" multiline rows={4} style={{ width: "40vw", marginBottom: "14px", backgroundColor: "#f7f7f7", }}
                    onChange={e => { this.setState({ newEventNotes: e.target.value }) }} /></div>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 275 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">Link to an application (Optional)</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            style={{ marginLeft: "-8px" }}
                            label="job"
                            onChange={(e) => {this.setState({newEventApplication: e.target.value})}}
                        >
                            {this.state.userJobs.map(job => {
                                let color = "#A7A7A7"
                                let backgroundColor = "#F2F2F2"
                                switch (job.status) {
                                    case "Applied":
                                        color="#355C67";
                                        backgroundColor="#dcf0f5"
                                        break;
                                    case "Interviewing":
                                        color="#E09F3E";
                                        backgroundColor ="#faebd4";
                                        break;
                                    case "Offer":
                                        color="#55A630";
                                        backgroundColor="#d8f2cb";
                                        break;
                                    case "Rejected":
                                        color="#940B0E";
                                        backgroundColor = "#fcdcdd";
                                        break;
                                    
                                }
                                return <MenuItem value={job}>
                                            <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                                                <div>
                                                    <span style={{fontWeight: "bold"}}>
                                                        {job.company}
                                                    </span>&nbsp;
                                                    <span style={{color: "grey"}}>
                                                        {job.jobName.length < 20 ? job.jobName : `${job.jobName.slice(0,20)}...`}
                                                    </span>
                                                </div>
                                                <div style={{color, backgroundColor, fontWeight: "bold", padding: "2px", paddingLeft: "10px", paddingRight: "10px", borderRadius: "5px", marginLeft: "10px", fontSize: "0.8em"}}>
                                                    {job.status}
                                                </div>
                                            </div>
                                    </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                    { this.state.newEventTitle != null && this.state.newEventDate != null ? <Button variant="contained" color="primary" style={{ height: "40px", VerticalAlign: "middle", marginTop: "7px" }}
                        onClick={this.addEvent}>Add Event</Button> 
                        : 
                        <Tooltip title="Event Title and Date cannot be empty">
                            <span><Button variant="contained" disabled style={{ height: "40px", VerticalAlign: "middle", marginTop: "7px" }}>Add Event</Button></span>
                        </Tooltip>
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={this.state.editModalIsOpen}
                onRequestClose={this.closeEditModal}
                style= {{
                    content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                    }
                }}
                contentLabel="Example Modal"
                >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>{" "}</div>
                    <div style={{ textAlign: "center", fontSize: "1.5em", marginBottom: "20px" }}>{this.state.editModalEventTitle}</div>
                    <button style={{ marginTop: "-20px" }} onClick={this.closeEditModal}><CloseIcon /></button>
                </div>
                <div><TextField id="outlined-basic" defaultValue={this.state.editModalEventTitle} label="Event Title" variant="outlined" style={{ width: "40vw", marginBottom: "14px", backgroundColor: "#f7f7f7"}}
                    onChange={e => { this.setState({ editModalEventTitle: e.target.value }) }} /></div>
                <div style={{width: "40vw", marginBottom: "14px"}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{display: "flex", }}>
                                <div style={{marginRight: "10px",}}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={this.state.editModalEventDate}
                                        renderInput={(params) => <TextField {...params} style={{ backgroundColor: "#f7f7f7", }} />}
                                        onChange={(newValue) => {this.setState({editModalEventDate: newValue})}}
                                        
                                    />
                                </div>
                                <div>
                                    <TimePicker
                                        label="Time"
                                        value={this.state.editModalEventDate}
                                        onChange={(newValue) => {this.setState({editModalEventDate: newValue})}}
                                        renderInput={(params) => <TextField {...params} style={{backgroundColor: "#f7f7f7"}} />}
                                    />
                                </div>
                            </div>
                    </LocalizationProvider>
                </div>
                <div><TextField id="outlined-basic" defaultValue={this.state.editModalEventCompany} label="Company (Optional)" variant="outlined" style={{ width: "40vw", marginBottom: "14px", backgroundColor: "#f7f7f7" }}
                    onChange={e => { this.setState({ editModalEventCompany: e.target.value }) }} /></div>
                <div><TextField id="outlined-basic" defaultValue={this.state.editModalEventNotes} label="Notes (Optional)" variant="outlined" multiline rows={4} style={{ width: "40vw", marginBottom: "14px", backgroundColor: "#f7f7f7" }}
                    onChange={e => { this.setState({ editModalEventNotes: e.target.value }) }} /></div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                        <div>
                            <Button variant="contained" style={{ height: "40px", VerticalAlign: "middle", marginTop: "7px", marginRight: "10px"}}
                                onClick={this.editEvent}>Save Changes</Button>
                            <Button variant="text" style={{ height: "40px", VerticalAlign: "middle", marginTop: "7px", color: "grey"}}
                                onClick={this.closeEditModal}>Cancel</Button>
                        </div>
                        <div>
                            <Button variant="text" color="error" style={{ height: "40px", VerticalAlign: "middle", marginTop: "7px" }}
                                onClick={this.delete}>Delete Event</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            {!this.state.modalIsOpen && !this.state.editModalIsOpen && <FullCalendar
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                weekends={true}
                events={this.state.eventRefs}
                eventContent={this.renderEventContent}
                aspectRatio="1.75"
            />}
        </div>
        );
    }
}