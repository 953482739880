import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import ClipLoader from "react-spinners/ClipLoader";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { LooksOne, LooksTwo, Looks3, AutoFixHigh } from '@mui/icons-material';



const AddModal = (props) => {
    const { jobAddModalIsOpen, newJobName, newJobStatus, newJobPortalLink, newJobLocation,
        newJobApplicationLink, newJobCompany, newJobNotes, modalTabIndex,
        changeModalTabIndex, changeContinueTabIndex, closeJobAddModal, autofillLoading, addJob,
        autoFillJob, autoFillCancel, changeName, changeCompany, changePortalLink,
        changeNotes, changeStatus, changeApplicationLink, changeAutoFillLink, confirmOpen,
        openConfirmDialog, closeConfirmDialog } = props;

    //const [pageIndex, setpageIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    //const [confirmOpen, setconfirmOpen] = useState(false);

    useEffect(() => {
        setProgress(0);
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 5));
        }, 9000);

        return () => {
            clearInterval(timer);
        };
    }, []);



    // const handleCloseConfirmDialog = (event) => {
    //     setconfirmOpen(false)
    // }

    // const handleOpenConfirmDialog = (event) => {
    //     setconfirmOpen(true)
    // }

    // const handleTabChange = (event, newTab) => {
    //     setpageIndex(newTab)
    // };

    // const handleContinueChange = (event) => {
    //     let currentIndex = pageIndex
    //     if (currentIndex == 3) {
    //         setpageIndex(0)
    //     } else {
    //         setpageIndex(currentIndex += 1)
    //     }
    // }

    // return (
    //     <Modal
    //         isOpen={jobAddModalIsOpen}
    //         onRequestClose={closeJobAddModal}
    //         style={{
    //             content: {
    //                 top: '50%',
    //                 left: '50%',
    //                 right: 'auto',
    //                 bottom: 'auto',
    //                 // width: '750px',
    //                 // height: "640px",
    //                 //alignItems: "center",
    //                 //display: "flex",
    //                 justifyContent: "center",
    //                 marginRight: '-50%',
    //                 transform: 'translate(-50%, -50%)'
    //             },
    //         }}
    //         contentLabel="Example Modal"
    //     >

    //         <div style={{ display: "flex", justifyContent: "space-between" }}>
    //             <div>{""}</div>
    //             <button onClick={closeJobAddModal}><CloseIcon /></button>
    //         </div>

    //         <div style={{ marginTop: "150px", width: "600px", alignContent: "center", height: "300px" }}>
    //             <Box textAlign='center'>
    //                 <div>Getting the data! It'll be just a second!</div>
    //             </Box>
    //             <LinearProgress style={{ marginTop: "20px" }} variant="determinate" value={progress} />
    //             <Box textAlign='center'>
    //                 <Button variant="contained" style={{ height: "40px", marginTop: "15px" }} onClick={autoFillCancel}>Cancel</Button>
    //             </Box>
    //         </div>
    //     </Modal>

    // )

    //console.log(this.state)
    if (autofillLoading) {
        return (
            <Modal
                isOpen={jobAddModalIsOpen}
                onRequestClose={closeJobAddModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',

                        justifyContent: "center",
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)'
                    },
                }}
                contentLabel="Example Modal"
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>{""}</div>
                    <button onClick={closeJobAddModal}><CloseIcon /></button>
                </div>

                <div style={{ marginTop: "150px", width: "600px", alignContent: "center", height: "300px" }}>
                    <Box textAlign='center'>
                        <div>Getting the data! It'll be just a second!</div>
                    </Box>
                    <LinearProgress style={{ marginTop: "20px" }} variant="determinate" value={progress} />
                    <Box textAlign='center'>
                        <Button variant="contained" style={{ height: "40px", marginTop: "15px" }} onClick={autoFillCancel}>Cancel</Button>
                    </Box>
                </div>
            </Modal>
        );
    }
    return (


        <Modal
            isOpen={jobAddModalIsOpen}
            onRequestClose={openConfirmDialog}
            size='large'
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 30, 43, 0.5)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)'
                },
            }}
            contentLabel="Example Modal"
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{" "}</div>
                <div style={{ textAlign: "center", fontSize: "1.5em", marginBottom: "20px" }}>Job Details</div>
                <button style={{ marginTop: "-20px" }} onClick={openConfirmDialog}><CloseIcon /></button>
                <Dialog
                    open={confirmOpen}
                    onClose={closeConfirmDialog}
                >
                    <DialogTitle id="confirm-dialog">Close? </DialogTitle>
                    <DialogContent>Are you sure you want to close? Your changes will not be saved.</DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={closeConfirmDialog}
                        >
                            No, keep editing!
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={closeJobAddModal}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={modalTabIndex} onChange={changeModalTabIndex} centered>
                    {/* <Tab label="Autofill" /> */}
                    <Tab label="Basic Info" icon={<LooksOne color="primary" />} iconPosition="start" />
                    <Tab label="Links" icon={<LooksTwo color="primary" />} iconPosition="start" />
                    <Tab label="Notes" icon={<Looks3 color="primary" />} iconPosition="start" />
                </Tabs>
            </Box>
            {/* {modalTabIndex == 0 &&
                <div>

                    <div style={{ marginTop: "10px", width: "fill", height: "300px", paddingLeft: "90px", paddingRight: "90px" }}>
                        <div style={{ fontSize: "1.2em", marginBottom: "20px" }}>Paste in a Linkedin URL to autofill your new job details! </div>
                        <TextField id="outlined-basic" label="Ex. www.linkedin.com/samplejob" variant="outlined" style={{ width: "27vw", marginBottom: "14px", marginRight: "0.5vw" }}
                            onChange={e => { changeAutoFillLink(e.target.value) }} />
                        <Button variant="outlined" startIcon={<AutoFixHigh color="primary" />} style={{ width: "9vw", height: "53px" }} onClick={autoFillJob} >Autofill</Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{" "}</div>
                        <Button variant="contained" style={{ height: "40px", width: "15%", marginTop: "7px" }} onClick={changeContinueTabIndex}>Skip</Button>
                    </div>
                </div>
            } */}
            {modalTabIndex == 0 &&
                <div>
                    <div style={{ marginTop: "10px", width: "fill", height: "300px", paddingLeft: "90px", paddingRight: "90px" }}>
                        <div style={{ fontSize: "1.2em", marginBottom: "10px" }}>Basic Info</div>
                        <div><TextField id="outlined-basic" label="Job Title" variant="outlined" value={newJobName} style={{ width: "35vw", marginBottom: "14px" }} label="Job Title"
                            onChange={e => { changeName(e.target.value) }} /></div>
                        <div><TextField id="outlined-basic" label="Company" variant="outlined" value={newJobCompany} style={{ width: "35vw", marginBottom: "14px" }} label="Company"
                            onChange={e => { changeCompany(e.target.value) }} /></div>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Status"
                                style={{ marginLeft: "-8px" }}
                                value={newJobStatus}
                                onChange={e => { changeStatus(e.target.value) }}
                            >
                                <MenuItem value={"Looking"}>Looking</MenuItem>
                                <MenuItem value={"Applied"}>Applied</MenuItem>
                                <MenuItem value={"Interviewing"}>Interviewing</MenuItem>
                                <MenuItem value={"Offer"}>Offer</MenuItem>
                                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{" "}</div>
                        <Button variant="contained" style={{ height: "40px", width: "15%", marginTop: "7px" }} onClick={changeContinueTabIndex}>Continue</Button>
                    </div>
                </div>

            }
            {modalTabIndex == 1 &&
                <div>
                    <div style={{ marginTop: "10px", width: "fill", height: "300px", paddingLeft: "90px", paddingRight: "90px" }}>
                        <div style={{ fontSize: "1.2em", marginBottom: "10px" }}>Links</div>
                        <div><TextField id="outlined-basic" label="Application Link" variant="outlined" value={newJobApplicationLink} style={{ width: "35vw", marginBottom: "14px" }}
                            onChange={e => { changeApplicationLink(e.target.value) }} /></div>
                        <div><TextField id="outlined-basic" label="Portal Link" variant="outlined" value={newJobPortalLink} style={{ width: "35vw", marginBottom: "14px" }} label="Portal Link"
                            onChange={e => { changePortalLink(e.target.value) }} /></div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{" "}</div>
                        <Button variant="contained" style={{ height: "40px", width: "15%", marginTop: "7px" }} onClick={changeContinueTabIndex}>Continue</Button>
                    </div>
                </div>

            }
            {modalTabIndex == 2 &&
                (newJobName == "" || newJobStatus == "" || newJobCompany == null ?
                    <div>
                        <div style={{ marginTop: "10px", width: "fill", height: "300px", paddingLeft: "90px", paddingRight: "90px" }}>
                            <div style={{ fontSize: "1.2em", marginBottom: "10px" }}>Notes</div>
                            <div><TextField id="outlined-basic" label="Notes" value={newJobNotes} variant="outlined" multiline rows={9} style={{ width: "35vw", marginBottom: "14px" }} onChange={e => { changeNotes(e.target.value) }} /></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>{" "}</div>
                            <Tooltip title={<div style={{ fontSize: "1.1em" }}>Title, Status, and Company are required</div>} style={{ fontSize: "1em" }}>
                                <span style={{ display: "inline-block" }}>
                                    <Button variant="contained" disabled style={{ height: "40px", width: "100px", marginTop: "7px" }}
                                        onClick={addJob}>Save</Button>
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    :
                    <div>
                        <div style={{ marginTop: "10px", width: "fill", height: "300px", paddingLeft: "90px", paddingRight: "90px" }}>
                            <div style={{ fontSize: "1.2em", marginBottom: "10px" }}>Notes</div>
                            <div><TextField id="outlined-basic" label="Notes" value={newJobNotes} variant="outlined" multiline rows={9} style={{ width: "35vw", marginBottom: "14px" }} onChange={e => { changeNotes(e.target.value) }} /></div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>{" "}</div>
                            <Button variant="contained" style={{ height: "40px", width: "15%", marginTop: "7px" }}
                                onClick={addJob}>Save</Button>
                        </div>
                    </div>
                )

            }
        </Modal>);


}

export default AddModal;