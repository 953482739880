import React, { Component, useEffect, useState } from 'react';
import UserPieChartMinimized from './UserPieChartMinimized';
import Button from 'react-bootstrap/Button';
import 'bootstrap-icons/font/bootstrap-icons.css'



export default class UserMetricsWidget extends Component {



    constructor() {
        super();
        /* add new booleans for each widget */
        this.state = {
            open: true,
        };
    }

    handleExpand = (e) => {
        this.setState({ open: !this.state.open })
    }

    render() {
        return (

            <div className='widget-wrapper'>

                {this.state.open &&

                    <div className='widget' style={{width: "18vw"}}>
                        <div style={{width: "100%", marginBottom: "10px", borderBottom: "2px solid #447988", fontSize: "1.2em", fontWeight: "bold"}}>
                            User Metrics
                        </div>
                        <div className='row'>
                            <UserPieChartMinimized user={this.props.user} apiURL={this.props.apiURL}/>
                        </div>
                        {/* <div className="upcoming-widget-body">
                            Average time from application to offer is 35 days
                        </div> */}
                        {/* <Button onClick={(e) => { this.handleExpand(e) }}
                        variant="primary" 
                        style={{
                            position: "relative",
                            bottom: "-50px",
                            left: "120px",
                            backgroundColor: "#D9D9D9",
                            borderColor: "#D9D9D9",
                            borderRadius: "10px",
                            paddingBlock: "unset",
                            fontSize: "23px",
                            width: "60px",
                            height: "30px",
                            borderBlockWidth: '0px',
                            paddingLeft: "13px"
                        }}>
                        <i className="bi bi-three-dots" style={{
                            color: 'black',
                            display: 'inline-block',
                            width: "auto",
                            height: 'auto',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }} />
                    </Button> */}
                    </div>

                }

                {!this.state.open &&
                    <div className='widget-expanded' style={{
                        marginTop: '0px',
                        width: '360px'
                    }}>
                        <UserPieChartMinimized />
                        <div className="upcoming-widget-body">
                            Average time from application to offer is 35 days
                        </div>
                        <Button onClick={(e) => { this.handleExpand(e) }}
                            variant="primary"
                            style={{
                                position: "relative",
                                bottom: "-230px",
                                left: "120px",
                                backgroundColor: "#D9D9D9",
                                borderColor: "#D9D9D9",
                                borderRadius: "10px",
                                paddingBlock: "unset",
                                fontSize: "23px",
                                width: "60px",
                                height: "30px",
                                borderBlockWidth: '0px',
                                paddingLeft: "13px"
                            }}>
                            <i className="bi bi-three-dots" style={{
                                color: 'black',
                                display: 'inline-block',
                                width: "auto",
                                height: 'auto',
                                alignContent: 'center',
                                justifyContent: 'center',
                            }} />
                        </Button>

                        <div className="expand-widget">
                            <div className='upcoming-widget-body'>
                                Where you have applied
                            </div>
                            <a href="https://imgbb.com/"><img src="https://i.ibb.co/SBKHBhB/america-map.png" alt="america-map" border="0" /></a>
                        </div>

                    </div>

                }


            </div>
        );
    }


}
