import React, {Component, useEffect, useState} from 'react';

const months=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export default class UpcomingWidget extends Component {

    render(){
        const date = new Date()
        return(
            <div className='widget-wrapper'>
                <div className='upcoming' style={{
                    overflow: 'auto',
                    width: "18vw",
                    height: "35vh"
                }}>
                     <div style={{width: "100%", marginBottom: "10px", fontSize: "2.5em", fontWeight: "bold"}}>
                         {months[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
                     </div>
                </div>
            </div>
            
        );
    }


}