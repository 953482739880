import React, { useState}  from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import validator from 'validator';
import axios from "axios";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const DetailModal = (props) => {
    const {currJob, jobDetailModalIsOpen, closeJobDetailModal, events, openDeleteModal, apiURL, mount} = props;
    const [addingEvent, setAddingEvent] = useState(false);
    const [newEventDate,setNewEventDate] = useState(null);
    const [newEventTitle,setNewEventTitle] = useState(null);
    const [newEventNotes,setNewEventNotes] = useState(null);
    const [deletingEvent,setDeletingEvent] = useState(false);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const lc = `https://leetcode.com/company/${currJob.company.replace(" ", "-")}`;
    const blind = `https://teamblind.com/company/${currJob.company.replace(" ", "-")}`;
    const levels_fyi = `https://levels.fyi/company/${currJob.company.replace(" ", "-")}`;

    console.log(events)

  const update = async (status) => {
    if (deletingEvent && currJob.upcomingEvent != null) {
      axios.post(`${apiURL}/delete-event`, {_id: currJob.upcomingEvent}).then((response) => {
        if (response.status === 200) {
          console.log("it worked")
        }
      });
    }
    if (addingEvent) {
      const event = {
        name: newEventTitle,
        date: newEventDate,
        useremail: props.useremail,
        notes: newEventNotes,
        company: currJob.company
      }
      axios.post(`${apiURL}/add-event`, event).then((response) => {
        if (response.status === 200) {
            currJob.upcomingEvent = response.data._id;
            if (status != null) {
              currJob.status = status;
            }
            console.log(currJob)
            axios.post(`${apiURL}/user-job-update`, currJob).then((response) => {
              if (status == null) {
                closeJobDetailModal();
                mount();
              }
            });
        }
        setAddingEvent(false);
        setNewEventDate(null);
        setNewEventTitle(null);
        setNewEventNotes(null);
        setDeletingEvent(false);
      });
    } else {
      axios.post(`${apiURL}/user-job-update`, currJob).then((response) => {
        if (status == null) {
          closeJobDetailModal();
          mount();
        }
      });
      setAddingEvent(false);
      setNewEventDate(null);
      setNewEventTitle(null);
      setNewEventNotes(null);
      setDeletingEvent(false);
    }
  }

  let time = null;
  let eventDate = null;
  if (events.hasOwnProperty(currJob.upcomingEvent)) {
    eventDate = new Date(events[currJob.upcomingEvent].date);
    const hours = eventDate.getHours();
    const minutes = eventDate.getMinutes();
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    time = `${hours12}:${minutes.toString().padStart(2, '0')} ${meridiem}`;
  }

    return  (<Modal
    isOpen={jobDetailModalIsOpen}
    onRequestClose={closeJobDetailModal}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 30, 43, 0.5)'
      },
      content: {
        top: '54.2%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    }}
    contentLabel="Example Modal"
  >
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>{" "}</div>
      <div style={{ textAlign: "center", fontSize: "1.5em", margin: "20px", marginTop: "0px", maxWidth: "60vw"}}>{currJob.company} | {currJob.jobName.length < 50 ? currJob.jobName : `${currJob.jobName.slice(0,50)}...`}</div>
      <button style={{ marginTop: "-20px" }} onClick={() => {
        closeJobDetailModal();
        setAddingEvent(false);
        setNewEventDate(null);
        setNewEventTitle(null);
        setNewEventNotes(null);
        setDeletingEvent(false);
        mount();
        }}><CloseIcon /></button>
    </div>
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <div style={{marginRight: "30px"}}>
        <div style={{fontSize: "1.2em", marginBottom: "10px"}}>Basic Info</div>
        <div><TextField id="outlined-basic" label="Job Title" variant="outlined" style={{ width: "30vw", marginBottom: "14px" }} defaultValue={currJob.jobName}
          onChange={e => {currJob.jobName=e.target.value}}/></div>
        <div><TextField id="outlined-basic" label="Company Name" variant="outlined" style={{ width: "30vw", marginBottom: "14px" }} defaultValue={currJob.company}
          onChange={e => {currJob.company = e.target.value}}/></div>
        <div style={{fontSize: "1.2em", marginBottom: "10px"}}>Links</div>
        <div>
          <TextField id="outlined-basic" label="Application Link" variant="outlined" style={{ width: "22vw", marginBottom: "14px", marginRight: "0.5vw"}} defaultValue={currJob.applicationLink}
          onChange={e => {currJob.applicationLink = e.target.value}}/>
          <Button variant="outlined" style={{width: "7.5vw", height: "54px"}} onClick={()=> {window.open(currJob.applicationLink, "_blank")}}>Go To Application</Button>
          </div>
        <div>
          <TextField id="outlined-basic" label="Portal Link" variant="outlined" style={{ width: "22vw", marginBottom: "14px", marginRight: "0.5vw"}} defaultValue={currJob.portalLink}
          onChange={e => {currJob.portalLink = e.target.value}}/>
          <Button variant="outlined" style={{width: "7.5vw", height: "54px"}} onClick={()=> {window.open(currJob.portalLink, "_blank")}}>Go To Portal</Button>
        </div>
        <div style={{fontSize: "1.2em", marginBottom: "10px"}}>Recruiter Info</div>
        <div><TextField id="outlined-basic" label="Recruiter Name" variant="outlined" style={{ width: "30vw", marginBottom: "14px" }} defaultValue={currJob.recruiterName}
          onChange={e => {currJob.recruiterName = e.target.value}}/></div>
        <div><TextField id="outlined-basic" label="Recruiter Email" variant="outlined" style={{ width: "30vw", marginBottom: "14px" }} defaultValue={currJob.recruiterEmail}
          onChange={e => {currJob.recruiterEmail = e.target.value}}/></div>
      </div>
      <div>
        <div style={{marginBottom: "10px"}}>
        <div style={{fontSize: "1.2em", marginBottom: "4px"}}>Upcoming Interviews/deadlines</div>
          {events.hasOwnProperty(currJob.upcomingEvent) ? 
          <div>
              <div style={{marginLeft: "10px", padding: "2px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <div><span style={{fontWeight: "bold"}}>{events[currJob.upcomingEvent].name}</span> | {events[currJob.upcomingEvent].company}</div>
                <Button color="error" size="small" style={{marginBottom: "-5px"}} onClick={() => {
                    setDeletingEvent(true);
                    delete events[currJob.upcomingEvent];
                }}>Delete Event</Button>
                </div>
                <div>{daysOfWeek[eventDate.getDay()]}, {months[eventDate.getMonth()]} {eventDate.getDate()} at {time}</div>
                <div style={{width: "500px"}}>{events[currJob.upcomingEvent].notes}</div>
            </div>
          </div> : 
          <div>
            {!addingEvent && <div style={{width: "30vw", fontSize: "0.8em", marginBottom: "10px"}}>There are no upcoming interviews/deadlines for with this role</div>}
          </div>
        }
        {addingEvent ? 
        <div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
          <div style={{marginBottom: "4px"}}>New Event</div>
          <Button color="error" variant="text" style={{fontSize: "0.8em", marginTop: "-4px", marginBottom: "4px"}} onClick={()=>{
                setAddingEvent(false);
                setNewEventDate(null);
                setNewEventTitle(null);
                setNewEventNotes(null);
                setDeletingEvent(false);
                }}>Cancel</Button>
          </div>
          <div><TextField id="outlined-basic" label="Event Title" variant="outlined" size="small" style={{ width: "442px", marginBottom: "14px", fontSize: "0.2em"}}
                    onChange={e => {setNewEventTitle(e.target.value)}} /></div>
                <div style={{marginBottom: "14px"}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{display: "flex"}}>
                                <div style={{marginRight: "10px", justifySelf: 'flex-start', width: "216px"}}>
                                    <DesktopDatePicker
                                        label="Date"
                                        size="small"
                                        inputFormat="MM/DD/YYYY"
                                        value={newEventDate}
                                        renderInput={(params) => <TextField  {...params} />}
                                        onChange={(newValue) => {setNewEventDate(newValue)}}
                                    />
                                </div>
                                <div style={{justifySelf: "flex-end", width: "216px"}}>
                                    <TimePicker
                                        label="Time"
                                        value={newEventDate}
                                        size="small"
                                        onChange={(newValue) => {setNewEventDate(newValue)}}
                                        renderInput={(params) => <TextField  {...params} />}
                                    />
                                </div>
                            </div>
                    </LocalizationProvider>
                </div>
                <div><TextField id="outlined-basic" label="Notes (Optional)" size="small" variant="outlined" multiline rows={2} style={{ width: "442px", marginBottom: "14px"}}
                    onChange={e => {setNewEventNotes(e.target.value)}} /></div>
                <div>
                </div>
        </div> : 
        <div>
          {!events.hasOwnProperty(currJob.upcomingEvent) && <Button variant="outlined" style={{fontSize: "0.8em"}} onClick={()=>{setAddingEvent(true)}}>Add an event</Button>}
        </div>}
        </div>
        <div style={{fontSize: "1.2em", marginBottom: "10px"}}>Notes</div>
        <div><TextField id="outlined-basic" label="Notes" variant="outlined" multiline rows={addingEvent ? 2 : 8} style={{ width: "30vw", marginBottom: "14px" }} defaultValue={currJob.notes}
        onChange={e => {currJob.notes = e.target.value}}/></div>
        <div style={{fontSize: "1.2em"}}>Links </div>
        <div>
          {validator.isURL(levels_fyi) && <Button variant="outlined" style={{marginRight: "10px"}} onClick={() => {window.open(levels_fyi,"_blank")}}>Levels.fyi</Button>}
          {validator.isURL(blind) && <Button variant="outlined" style={{marginRight: "10px"}} onClick={() => {window.open(blind,"_blank")}}>Blind</Button>}
          {validator.isURL(lc) && <Button variant="outlined" onClick={() => {window.open(lc,"_blank")}}>Leetcode</Button>}
        </div>
      </div>
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="Status"
          style={{ marginLeft: "-8px" }}
          onChange={e => {currJob.status = e.target.value}}
          defaultValue={currJob.status}
        >
          <MenuItem value={"Looking"}>Looking</MenuItem>
          <MenuItem value={"Applied"}>Applied</MenuItem>
          <MenuItem value={"Interviewing"}>Interviewing</MenuItem>
          <MenuItem value={"Offer"}>Offer</MenuItem>
          <MenuItem value={"Rejected"}>Rejected</MenuItem>
        </Select>
      </FormControl>
      <div style={{display: "flex"}}>
      <Button color="error" style={{alignSelf: "center"}} onClick={() => { 
                                                openDeleteModal();
                                                closeJobDetailModal();
                                               }}>Delete from Applications</Button>
      <Button variant="contained" style={{ height: "40px", VerticalAlign: "middle", marginTop: "7px", marginLeft: "10px"}}
        onClick={() => { update(null) }}>Save Changes</Button>
      </div>
    </div>
  </Modal>);
}

export default DetailModal;