import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from "axios";

export default class StartupWidget extends Component {

    constructor() {
        super();

        this.state = {
            open: false,
            startup: null,
        };
    }

    componentDidMount() {
        axios.get(`${this.props.apiURL}/startup-data`, {}).then((response) => {
    
            let startups = [];
            for (let startup of response.data) {
                let startupData = [startup.title, startup.image, startup.description, startup.facts, startup.link]
                startups.push(startupData);
            }  
            // startups will rotate with the day
            let currDay = parseInt((new Date().getTime()) / 86400000);
            let startupIndex = currDay % startups.length;
            let startupOTD = startups[startupIndex];
            if ( startups[0][0] != null ) {
                this.setState({startupTitle : startups[0][0]});
            }
            if ( startups[0][1] != null ) {
                this.setState({startupImage : startups[0][1]});
            }
            if ( startups[0][2] != null ) {
                this.setState({startupDescription : startups[0][2]});
            }
            if ( startups[0][3] != null ) {
                this.setState({startupFacts : startups[0][3]});
            }
            if ( startups[0][4] != null ) {
                this.setState({startupLink : startups[0][4]});
            }
         });
 

      }


    handleExpand = (e) => {
        this.setState({ open: !this.state.open })
    }

    componentDidMount() {
        axios.post(`${this.props.apiURL}/startup-data`, {}).then((response) => {
            if (response.data.length == 1) {
                this.setState({startup: response.data[0]})
            }
          });
    }

    render() {
        return (

            <div className='widget-wrapper'>
                {this.state.startup != null && <div className='widget' style={{
                    width: '25vw',
                    maxHeight: "82vh"
                }}>
                    <div style={{width: "100%", marginBottom: "10px", borderBottom: "2px solid #447988", fontSize: "1.2em", fontWeight: "bold"}}>
                        Startup of the day
                    </div>
                    <div style={{maxHeight: "68vh", overflow: "auto"}}>
                        <button onClick={() => {window.open(this.state.startup.link, "_blank")}} style={{width: "100%", display: "flex", marginBottom: "10px"}}>
                            <img src={this.state.startup.image} style={{width: "40px", borderRadius: "10px"}}/>
                            <div style={{fontSize: "1.3em", marginTop: "3px", fontWeight: "bold", marginLeft: "10px"}}>{this.state.startup.title}</div>
                        </button>
                        <div style={{fontWeight: "bold"}}>
                            What they do
                        </div>
                        <div style={{marginBottom: "5px"}}>
                            {this.state.startup.description}
                        </div>
                        <div style={{fontWeight: "bold", marginBottom: "4px"}}>
                            Quick Facts
                        </div>
                        <div>
                        <div style={{display: "flex", flexWrap: "wrap", marginBottom: "5px"}}>
                            {this.state.startup.facts.split(";").map((fact) => {
                                return <div style={{backgroundColor: "#cbeef7", color: "#3c6975", fontWeight: "bold", padding: "2px", paddingLeft: "10px", paddingRight: "10px", marginRight: "6px", marginBottom: "6px", borderRadius: "25px"}}>{fact}</div>
                            })}
                        </div>
                        </div>
                        <div style={{fontWeight: "bold", marginBottom: "4px"}}>
                            Funding
                        </div>
                        <div style={{display: "flex", flexWrap: "wrap", marginBottom: "5px"}}>
                            {this.state.startup.funding.split(",").map((fundingInfo) => {
                                return <div style={{backgroundColor: "#cbeef7", color: "#3c6975", fontWeight: "bold", padding: "2px", paddingLeft: "10px", paddingRight: "10px", marginBottom: "6px", marginRight: "6px", borderRadius: "25px"}}>{fundingInfo}</div>
                            })}
                        </div>
                        <Button id="visit-website-btn" variant="outlined" style={{ marginTop: "10px"}} onClick={() => {window.open(this.state.startup.link, "_blank")}}>Visit Website</Button>
                    </div>
                </div>}
            </div>

        )
    }
}
