import React from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-modal';

const DeleteModal = (props) => {
    const {deleteJob, deleteModalIsOpen, closeDeleteModal, toDelete} = props;
    return  <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 30, 43, 0.5)'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
        contentLabel="Example Modal"
      >
        {toDelete != null && <div style={{marginBottom: "10px", maxWidth: "25vw", textAlign: "center"}}>Are you sure you want to remove <span style={{fontWeight: "bold"}}>{toDelete.company}</span> from your applications?</div>}
        <div style={{display: "flex", justifyContent: "center"}}>
          <Button style={{color: "grey"}} onClick={() => {closeDeleteModal()}}>Cancel</Button>
          <Button variant="outlined" color="error" onClick={() => {
            deleteJob(toDelete.id);
            closeDeleteModal();
  
          }}>Delete</Button>
        </div>
      </Modal>;
}

export default DeleteModal;