import React, { Component, setState } from 'react';
import googleImage from "../images/google.png";
import githubImage from "../images/github.png";
import logoblue from "../images/logoblue.png";
import MuiAlert from '@mui/material/Alert';
import EmailIcon from '@mui/icons-material/Email';
import BasicSlider from './BasicSlider';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default class Login extends Component {

  render() {
    let details = navigator.userAgent;
  
    let regexp = /android|iphone|kindle|ipad/i;
  
    let isMobileDevice = regexp.test(details);
  
if (isMobileDevice) {
    return(
        <div className="mobile-friendly-warning-container">
            <a style={{
                marginTop: "auto",
                marginBottom: "auto",
                color: "#447988",
                fontSize: "1.2em"
            }}>clong.fyi is not mobile friendly at this time</a>
        </div>
    );
} else {
    return(
        <div className="login-panels-container" id="login-panel-1">
                <div style={{width: "100%", display: "flex", height: "85%"}}>
                    <div style={{width:"35%"}}>
                        <div className='logo-wrapper' 
                            style={{ 
                                marginTop: "10vh",
                                marginLeft: "6vw",
                                fontSize: "1.3em",
                                letterSpacing: "1px",
                                color: "white"
                            }}>
                            <div>
                                <img className="logo-login" src={logoblue} 
                                    style={{
                                        width: "292px",
                                }}/>
                            </div>
                            <div style={{width: "30vw", fontWeight: "bold", marginBottom: "2vh", fontSize: "1.4em"}}>Get the Upper Edge on Tech Recruiting</div>
                                <div style={{marginBottom: "10px"}}>Ready to get Started?</div>
                                <div style={{color: "white", fontSize: "0.8em", width: "30vw", backgroundColor: "white", padding: "20px", borderRadius: "6px", marginBottom: "5vh"}}>
                                    <div style ={{color: "grey", width: "100%", fontWeight: "bold", marginBottom: "15px"}}>Choose a login method</div>
                                        <div
                                            className="google-button"
                                            onClick={()=>{window.open("http://localhost:8080/auth/google", "_self")}}
                                        >
                                            <div style={{display: "flex"}}>
                                                <img src={googleImage} style={{width:"22px", height: "22px", marginTop: "3px", marginRight: "14px", marginLeft: "4px"}}/>
                                                Google
                                            </div>
                                        </div>
                                        <div
                                            className="github-button"
                                            onClick={()=>{window.open("http://localhost:8080/auth/github", "_self")}}
                                        >
                                            <div style={{display: "flex"}}>
                                                <img src={githubImage} style={{width:"22px", height: "22px", marginTop: "3px", marginRight: "14px", marginLeft: "4px"}}/>
                                                Github
                                            </div>
                                        </div>
                                </div>
                        </div>
                    </div>
                    <div style={{width: "65vw", marginTop: "6vh", padding: "100px"}}>
                        <BasicSlider />
                    </div>
                </div>
                <div style={{width:"88vw", marginLeft: "6vw", display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                    <div style={{color: "white", letterSpacing: "1px", fontSize: "1.2em"}}>
                        <div>Curious to see how it works?</div>
                        <div style={{fontWeight: "bold"}}>Watch this demo</div>
                    </div>
                    <div style={{color: "white", letterSpacing: "1px", fontSize: "1.2em", textAlign: "right"}}>
                        <div>Comments, Questions Suggestions?</div>
                        <div style={{fontWeight: "bold"}}>Email Us</div>
                    </div>
                </div>
        </div>
    );
  }
}
}
