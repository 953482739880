import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import Button from '@mui/material/Button';
import Card from 'react-bootstrap/Card';
import validator from 'validator';

const NewJobCard = (props) => {
    const {job, levels_fyi, blind, lc, added, addToMyJobs} = props;
    return <Card style={{ 
        width: '96%',
        marginLeft: "2%",
        backgroundColor: "transparent", 
        marginBottom: "10px", 
        color: "black",
        border: "0px",
        borderRadius: "0px"
      }} key={job._id}>
        <Card.Body style={{margin: "-5px", marginTop: "-10px"}}>
          <Row style={{marginBottom: "-5px"}}>
            <Col sm={8}>
              <Card.Title style={{fontSize: '1.2em', letterSpacing: "1px"}}>
                <span style={{fontWeight: 'bold'}}>{job.name}</span>
                &nbsp; <span style={{color: "grey", fontSize: "0.93em"}}>{job.type}</span>
              </Card.Title>
            </Col>
            <Col>
              <div className="new-job-secondary-buttons">
                {validator.isURL(levels_fyi) && <Button variant="text" style={{color: "#447988", margin: "0px", marginTop: "-5px"}} onClick={() => {window.open(levels_fyi,"_blank")}}>Levels.fyi</Button>}
                {validator.isURL(blind) && <Button variant="text" style={{color: "#447988", margin: "0px", marginTop: "-5px"}} onClick={() => {window.open(blind,"_blank")}}>Blind</Button>}
                {validator.isURL(lc) && <Button variant="text" style={{color: "#447988", margin: "0px", marginTop: "-5px"}} onClick={() => {window.open(lc,"_blank")}}>Leetcode</Button>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={10} style={{marginBottom: "5px"}}>
              {job.notes != null && <Card.Text style={{display: "inline", marginRight: "25px", fontSize: "1em"}}>
                {job.notes}
              </Card.Text>}
            </Col>
            <Col sm={6} style={{marginBottom: "0px"}}>
            {job.location != null && <Card.Text style={{display: "inline", marginRight: "25px", fontSize: "1em", color: "grey"}}>
                Location: {job.location}
              </Card.Text>}
            </Col>
            <Col style={{display: "flex",justifyContent: "flex-end"}}>
            {job.link != null && <Button size="small" color="success" variant="text" style={{margin: "-5px", marginRight: "20px", fontWeight: "bold"}} onClick={(e) => {window.open(job.link,"_blank")}}>Application Link</Button>}
              {added ?
                  <Button 
                    size="small"
                    variant="contained"
                    disabled

                    style={{margin: "-5px", color: "#878787", fontWeight: "bold"}}>Already added
                    </Button>
                :
                    <Button 
                      size="small"
                      variant="contained" 
                      color="success" 
                      onClick={() => {addToMyJobs(job)}}
                      style={{margin: "-5px", fontWeight: "bold"}}>Add to my jobs
                      </Button>
                  }
            
            </Col>
          </Row>
          <div style={{borderBottom: "1px solid #447988", marginTop: "15px", marginBottom: "-15px"}}></div>
        </Card.Body>
      </Card>
}

export default NewJobCard;