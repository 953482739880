import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

export default class ProjectsWidget extends Component {
    render() {
        return (
            <div className='widget-wrapper'>
                <div className='widget-header'>
                    Today's Personal Project Idea
                </div>
                <div className='widget' style={{ width: '350px' }}>
                    <i className="bi bi-lightbulb" style={{ fontSize: '25px' }}></i>
                    <span className='project-title'>
                        Calculator
                    </span>
                    <div className="widget-description-wrapper">
                        <span style={{fontWeight: 'bold'}}>
                            Description
                        </span>
                        <span className="project-description-body">
                            : Build a functional calculator app in your choice of language/platform. Customize it as much as you want!
                        </span>
                    </div>
                    <div className='project-tutorials-title'>
                        Tutorials:
                    </div>
                    <div className="row mt-2">
                        <div className="col text-center">
                            <Button variant="primary" style={{
                                backgroundColor: "#5A82CF",
                                alignItems: "right",
                                borderRadius: "10px",
                                fontSize: "10px",
                                width: "80px",
                                height: "auto",
                                right: "-560px",
                                padding: "4px"
                            }}>
                                Swift UI
                            </Button>
                        </div>
                        <div className="col text-center">
                            <Button variant="primary" style={{
                                backgroundColor: "#5A82CF",
                                alignItems: "center",
                                borderRadius: "11px",
                                fontSize: "11px",
                                width: "80px",
                                height: "auto",
                                right: "-560px",
                                padding: "4px"
                            }}>
                                React
                            </Button>
                        </div>
                        <div className="col text-center">
                            <Button variant="primary" style={{
                                backgroundColor: "#5A82CF",
                                alignItems: "left",
                                borderRadius: "10px",
                                fontSize: "11px",
                                width: "80px",
                                height: "auto",
                                right: "-560px",
                                padding: "4px"
                            }}>
                                React Native
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}