import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from "axios";
import validator from 'validator';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'; 

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default class SuggestedApplicationsWidget extends Component {

    constructor() {
        super();

        this.state = {
            open: false,
            job: null,
            myjobs: [],
            messageStatus: false,
        };
    }

    handleExpand = (e) => {
        this.setState({ open: !this.state.open })
    }

    componentDidMount() {
      console.log(this.props.user)
        axios.post(`${this.props.apiURL}/new-job-data`, {}).then((response) => {
            const job = response.data[Math.floor(Math.random()*response.data.length)];
            this.setState({job})
          });
          axios.post(`${this.props.apiURL}/job-data-user`, {useremail: this.props.user}).then((response) => {
            let list = [];
            for (let job of response.data) {
              if (job.jobId != null) {
                list.push(job.jobId);
              }
            }
            this.setState({myjobs: list});
          });
    }

    addToMyJobs = (job) => {
        let jobToAdd = {
          jobId: job._id,
          jobName: job.notes,
          company: job.name,
          status: "Looking",
          useremail: this.props.user,
          portalLink: "",
          applicationLink: job.link,
          notes: ''
        }
        axios.post(`${this.props.apiURL}/user-job-add`, jobToAdd).then((response) => {
          if (response.status == 200) {
            this.setState({myjobs: [...this.state.myjobs,job._id]})
            this.open();
          }
        });
      }

      open = () => {
        this.setState({messageStatus: true});
      }
    
      handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({messageStatus: false});
      }

    renderLinks = (job) => {
        const added = this.state.myjobs.includes(job._id) ? true : false;
        const lc = `https://leetcode.com/company/${job.name.replace(" ", "-")}`;
        const blind = `https://teamblind.com/company/${job.name.replace(" ", "-")}`;
        const levels_fyi = `https://levels.fyi/company/${job.name.replace(" ", "-")}`;
        return (<div style={{marginTop: "10px", display: "flex", justifyContent: "space-between"}}>
                    <div>
                        {validator.isURL(levels_fyi) && <Button variant="outlined" size="small" style={{marginRight: "10px"}} onClick={() => {window.open(levels_fyi,"_blank")}}>Levels.fyi</Button>}
                        {validator.isURL(blind) && <Button variant="outlined" size="small" style={{marginRight: "10px"}} onClick={() => {window.open(blind,"_blank")}}>Blind</Button>}
                        {validator.isURL(lc) && <Button variant="outlined" size="small" onClick={() => {window.open(lc,"_blank")}}>Leetcode</Button>}
                    </div>
                    {added ? 
                        <Button disabled size="small" style={{marginRight: "10px"}}>Already Added</Button> :
                        <Button color="success" variant="contained" size="small"
                            onClick={() => {this.addToMyJobs(job)}}>Add to my Applications</Button>
                        }
            </div>);
    }

    render() {
        return (

            <div className='widget-wrapper'>
                <Snackbar open={this.state.messageStatus} autoHideDuration={3500} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                  Job Added Successfully
                </Alert>
              </Snackbar>

                {this.state.job != null &&
                    <div className='widget' style={{
                        width: '42vw',
                        maxHeight: "35vh"
                    }}>
                        <div style={{width: "100%", marginBottom: "10px", borderBottom: "2px solid #447988", fontSize: "1.2em", fontWeight: "bold"}}>
                            Suggested Application
                        </div>
                        <div style={{overflow: "auto", maxHeight: "21.5vh"}}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div style={{fontSize: '1.2em', letterSpacing: "1px", width: "21vw"}}>
                                    <span style={{fontWeight: 'bold'}}>{this.state.job.name}</span>
                                    &nbsp; <span style={{color: "grey", fontSize: "0.93em"}}>{this.state.job.type}</span>
                                </div>
                                <Button variant="text" size="small" color="success" style={{marginRight: "10px"}} onClick={() => {window.open(this.state.job.link,"_blank")}}>Application Link</Button>
                            </div>
                            {this.state.job.notes != null && <div>
                                {this.state.job.notes}
                            </div>}
                            {this.state.job.location != null && <div style={{color: "grey"}}>
                                Location: {this.state.job.location}
                            </div>}
                            {this.renderLinks(this.state.job)}
                        </div>
                    </div>
                }
            </div>

        )
    }
}

