import './App.css';
import { Navigate } from "react-router-dom";
import NavBar from './components/navigationbar.js'
import MyJobs from './components/myjobs.js'
import NewJobs from './components/newjobs.js'
import axios from "axios";
import LandingPage from './components/landingpage';
import Calendar from './components/calendar';
import Login from './components/loginsignup';
import Profile from './components/profile';
import React, { useState, useEffect } from 'react';
import { firebaseApp } from "./FirebaseInit";
import firebase from 'firebase/compat/app';
import { useLocation } from 'react-router-dom'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRadioGroup } from '@mui/material';

const API_URL="http://localhost:8080";

const App = () => {

  const [user, editUser] = useState(null);
  const [selected, editSelected] = useState("dashboard");
  const [verified, setVerified] = useState(false);
  const [signedInWithPassport, editSignedIn] = useState(false);

  useEffect(() => {
    const getUser = () => {
      fetch("http://localhost:8080/auth/login/success", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          editSignedIn(true);
          editUser(resObject.user);
        })
        .catch((err) => {
          editSignedIn(false);
          firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              const userInfo = user.multiFactor.user;

              editUser({email: userInfo.email,
                displayName: userInfo.displayName,
                accessToken: userInfo.accessToken,
                id: userInfo.uid,});
              this.checkVerificationState();
            }
          })
        });
    };
    getUser();
  }, []);

  const logout = () => {
    if (signedInWithPassport) {
      window.open("http://localhost:8080/auth/logout","_self");
    } else {
      firebase.auth().signOut();
      editUser(null);
    }
  }

    return (
      <BrowserRouter>
        {user != null && <NavBar user={user} logout={logout} editSelected={editSelected} selected={selected} signedInWithPassport={signedInWithPassport}/>}
        <Routes>
          <Route path='/' exact element={ user != null ? <Navigate to="/dashboard"/> : <Login editUser={editUser} user={user} verified={verified} logout={logout}/>}/>
          <Route path='/dashboard' exact element={ user != null ? <LandingPage user={user.id} apiURL={API_URL}/> : <Navigate to="/" />} />
          <Route path='/myjobs' element={ user != null ? <MyJobs  user={user.id} apiURL={API_URL}/> : <Navigate to="/" />} />
          <Route path='/newjobs' element={ user != null ? <NewJobs  user={user.id} apiURL={API_URL}/> : <Navigate to="/" />} />
          {/* <Route path='/calendar' element={ user != null ? <Calendar user={user.id} apiURL={API_URL}/> : <Navigate to="/" />} /> */}
          <Route path='/profile' element={ user != null ? <Profile user={user.id} /> : <Navigate to="/" />}  />
        </Routes>
      </BrowserRouter>
    );
}

export default App;