import firebase from 'firebase/compat/app';

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAu2huMFG17d7yD7IdgtGSjpeQNRulDkXU",
//   authDomain: "clong-auth.firebaseapp.com",
//   projectId: "clong-auth",
//   storageBucket: "clong-auth.appspot.com",
//   messagingSenderId: "484624702090",
//   appId: "1:484624702090:web:8278e14434bbafc1e42b73"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDzMotRpToYl3Qhkz-IuoLi9vQ08kVBsGU",
  authDomain: "clong-auth-aff40.firebaseapp.com",
  projectId: "clong-auth-aff40",
  storageBucket: "clong-auth-aff40.appspot.com",
  messagingSenderId: "748470383070",
  appId: "1:748470383070:web:122f132d89e98644daed4f",
  measurementId: "G-XVR2Q143DG"
};

// Initialize Firebase
export var firebaseApp = firebase.initializeApp(firebaseConfig);