import React, { Component } from 'react';


export default class RecruitingWidget extends Component {

    render() {
        return (
            <div className='widget-wrapper'>
                <div className='widget-header'>
                    Today's Recruiting Advice
                </div>
                <div className='widget' style={{ 
                        overflow: 'auto',
                        width: '400px'
                    }}>
                    <i className="bi bi-chat" style={{ fontSize: '25px' }}></i>
                    <span className='recruiting-widget-tip'>
                        Find Referrals
                    </span>
                    <div className="widget-description-wrapper">
                        <span style={{fontWeight: 'bold'}}>
                            Why
                        </span>
                        <span className="recruiting-widget-why">
                            : Companies are more likely to hire based on internal recommendation.
                        </span>
                    </div>
                    <div className="widget-description-wrapper">
                        <span style={{fontWeight: 'bold'}}>
                            How
                        </span>
                        <span className="recruiting-widget-how">
                            : Start by searching your school alumni network and/or LinkedIn to find employees with common backgrounds and don't be afraid to cold email them! Regardless of their response you have gained a new contact in the industry.
                        </span>
                    </div>
                </div>
            </div>
        );
    }


}