import React, {Component, useEffect} from 'react';
import NewJobCard from './newjobindividualcard.js';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import axios from "axios";
import Button from '@mui/material/Button';
import Card from 'react-bootstrap/Card';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'; 
import ClipLoader from "react-spinners/ClipLoader";
import InfoIcon from '@mui/icons-material/Info';
import validator from 'validator';
import Switch from '@mui/material/Switch';
import { grey } from '@mui/material/colors';
import TextField from '@mui/material/TextField';

import { alpha, styled } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const GreySwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: alpha(grey[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: grey[800],
  },
}));

export default class NewJobs extends Component {
  state = {
    searched: "",
    newest: false,
    newGrads: false,
    jobs: null,
    myjobs: [],
    messageStatus: false,
  }

  componentDidMount() {
    axios.post(`${this.props.apiURL}/new-job-data`, {}).then((response) => {
      let jobs = response.data;
      this.setState({jobs: jobs});
      this.setState({show: response.data});
    });
    axios.post(`${this.props.apiURL}/job-data-user`, {useremail: this.props.user}).then((response) => {
      let list = [];
      for (let job of response.data) {
        if (job.jobId != null) {
          list.push(job.jobId);
        }
      }
      this.setState({myjobs: list});
    });
  }

  toggleNewGrad = (e) => {
    this.setState({newGrads: e.target.value})
  }

  toggleNewest = (e) => {
    if (e.target.value != this.state.newest) {
      this.setState({show: this.state.show.reverse()});
    }
    this.setState({newest: e.target.value})
  }

  addToMyJobs = (job) => {
    let jobToAdd = {
      jobId: job._id,
      jobName: job.notes,
      company: job.name,
      status: "Looking",
      useremail: this.props.user,
      portalLink: "",
      applicationLink: job.link,
      notes: ''
    }
    axios.post(`${this.props.apiURL}/user-job-add`, jobToAdd).then((response) => {
      if (response.status == 200) {
        this.setState({myjobs: [...this.state.myjobs,job._id]})
        this.open();
      }
    });
  }

  open = () => {
    this.setState({messageStatus: true});
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({messageStatus: false});
  }

  render() {
    return (
      <div>
        <div className="new-jobs-container">
          <div className="jobs-container">
            <div className="jobs-results-header">
              <Snackbar open={this.state.messageStatus} autoHideDuration={3500} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                  Job Added Successfully
                </Alert>
              </Snackbar>
              <div style={{alignSelf: "center", fontSize: "0.9em"}}>Roles for the 2023-2024 Recruiting Year <Tooltip style={{color: "grey", marginBottom: "1.5px"}} title="These jobs are sourced from various github lists such as coderquad and are updated regularly"><InfoIcon/></Tooltip></div>
                <TextField size="small" onChange={(e) => {this.setState({searched: e.target.value.toLowerCase()})}} style={{width: "100%", marginBottom: "10px"}} placeholder="Search Jobs, Companies, Locations" />
                <div style={{display: "flex"}}>
                  <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                    <div style={{fontSize: "0.8em", marginTop: "7px", marginRight: "5px"}}>Show me </div>
                    <FormControl size="small" style={{marginRight: "25px"}}>
                      <Select defaultValue={false} onChange={this.toggleNewest}>
                        <MenuItem value={false}>Oldest First</MenuItem>
                        <MenuItem value={true}>Newest First</MenuItem>
                      </Select>
                    </FormControl>
                    <div style={{fontSize: "0.8em", marginTop: "7px", marginRight: "5px"}}>Type </div>
                    <FormControl size="small">
                      <Select defaultValue={false} onChange={this.toggleNewGrad}>
                        <MenuItem value={false}>Internships</MenuItem>
                        <MenuItem value={true}>New Grad Roles</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {this.state.jobs != null ? 
                <div className="new-jobs-result">
                  {this.state.show.map(job => {
                    let type = "Internship";
                    if (this.state.newGrads) type = "New Grad";
                    let render = false;
                    if (this.state.searched == "") render = true;
                    else {
                      if (job.name.toLowerCase().includes(this.state.searched) || job.notes.toLowerCase().includes(this.state.searched) || job.location.toLowerCase().includes(this.state.searched)) render = true;
                    }
                    const added = this.state.myjobs.includes(job._id) ? true : false;
                    if (render && type == job.type) {
                      const lc = `https://leetcode.com/company/${job.name.replace(" ", "-")}`;
                      const blind = `https://teamblind.com/company/${job.name.replace(" ", "-")}`;
                      const levels_fyi = `https://levels.fyi/company/${job.name.replace(" ", "-")}`;
                      return <NewJobCard lc={lc} job={job} levels_fyi={levels_fyi} blind={blind} added={added} addToMyJobs={this.addToMyJobs}/>;
                    }
                  })}
                </div> 
                : 
                <div style={{marginLeft: "45%", marginTop: "5%"}}>
                  <ClipLoader
                      size={100}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                  />
                </div>}
              </div>
            </div>
          </div>
    );
  }
}
